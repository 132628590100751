export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Master',
  },
  {
    component: 'CNavItem',
    name: 'Cabang',
    to: '/cabang',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'User',
    to: '/user',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Pelanggan',
    to: '/pelanggan',
    icon: 'cil-star',
  },
  {
    component: 'CNavItem',
    name: 'Mitra',
    to: '/mitra',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Produk',
    to: '/produk',
    icon: 'cil-basket',
  },
  {
    component: 'CNavTitle',
    name: 'Pembelian',
  },
  {
    component: 'CNavItem',
    name: 'Purchase Order',
    to: '/po',
    icon: 'cil-file',
  },
  {
    component: 'CNavTitle',
    name: 'Penjualan',
  },
  {
    component: 'CNavItem',
    name: 'Sales Order',
    to: '/salesorder',
    icon: 'cil-file',
  },
  {
    component: 'CNavTitle',
    name: 'Transaksi',
  },
  {
    component: 'CNavItem',
    name: 'Upload',
    to: '/upload',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'Pengiriman',
    to: '/pengiriman',
    icon: 'cil-calculator',
  },
  {
    component: 'CNavItem',
    name: 'Update Pengiriman',
    to: '/updatepengiriman',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavItem',
    name: 'Report',
    to: '/report',
    icon: 'cil-notes',
  },
]
