export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Master',
  },
  {
    component: 'CNavItem',
    name: 'Cabang',
    to: '/cabang',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Pelanggan',
    to: '/pelanggan',
    icon: 'cil-star',
  },
  {
    component: 'CNavTitle',
    name: 'Pengiriman',
  },
  {
    component: 'CNavItem',
    name: 'Upload',
    to: '/upload',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'Pengiriman',
    to: '/pengiriman',
    icon: 'cil-calculator',
  },
  {
    component: 'CNavItem',
    name: 'Update Pengiriman',
    to: '/updatepengiriman',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavItem',
    name: 'Report',
    to: '/report',
    icon: 'cil-notes',
  },
]
